<template>
    <div>
      <div v-if="!showTip">
        <div class="header">
            <div></div>
            <div class="text">自主缴费系统</div>
            <div></div>
        </div>
        <Cell title="缴费信息">
            <van-cell title="学校" :value="currentInfo.schoolName" />
            <van-cell title="缴费项目" :value="currentInfo.paymentName" />
            <van-cell title="缴费金额" :value="currentInfo.paymentAmount + '元'" />
            <van-cell title="缴费说明" :value="currentInfo.paymentRemark" />
        </Cell>
        <Cell title="个人信息填写">
            <van-field
                v-model="params.gradeId"
                is-link
                readonly
                label="年级"
                placeholder="请选择学生所在年级"
                @click="showGradePicker = true"
            />
            <van-popup v-model:show="showGradePicker" round position="bottom">
                <van-picker
                    :columns="gradeColumns"
                    @confirm="confirmGrade"
                    @cancel="showGradePicker = false"
                />
            </van-popup>
            <van-field
                v-model="params.classId"
                is-link
                readonly
                label="班级"
                placeholder="请选择学生所在班级"
                @click="showClassPicker = true"
            />
            <van-popup v-model:show="showClassPicker" round position="bottom">
                <van-picker
                    :columns="classColumns"
                    @confirm="confirmClass"
                    @cancel="showClassPicker = false"
                />
            </van-popup>
            <van-field v-model="params.name" label="姓名" placeholder="请输入学生姓名" />
        </Cell>
        <van-radio-group v-model="payMethod">
  <van-cell-group inset>
    <div class="payMethod">
        <img style="width: 20px; float: left" src="@/assets/svg/wechat.svg" alt="">
        <van-cell title="微信支付" clickable @click="payMethod = '1'">
      <template #right-icon>
        <van-radio name="1" />
      </template>
    </van-cell>
    </div>
    <div class="payMethod">
        <img style="width: 20px" src="@/assets/svg/pay.svg" alt="">
        <van-cell title="支付宝支付" clickable @click="payMethod = '2'">
      <template #right-icon>
        <van-radio name="2" />
      </template>
    </van-cell>
    </div>
  </van-cell-group>
</van-radio-group>
<div class="btn">
    <van-button type="primary" @click="pay">立即支付</van-button>
</div>
<van-popup v-model:show="show">
    <div class="repeat">
        <div class="title">信息确认</div>
        <div class="desc">
            <p v-if="repeatNum > 1">您输入的学生姓名检测到{{repeatNum}}条重名信息，请确认需要缴费的学生后点击确定按钮。</p>
            <p>新教育号为每位学生在学校的唯一编号，您可通过新教育号来确认学生信息。</p>
        </div>
        <div class="repeatStd">
          <van-radio-group v-model="stdId" direction="horizontal">
            <div class="stdInfo" v-for="item in stdList" :key="item.classId">
                <van-radio :name="item.studentId">{{item.studentName}}    {{'新教育号: ' + item.studentCode}}</van-radio>
            </div>
        </van-radio-group>
        </div>
        <div class="btnPay">
            <div @click="cancel" class="cancel">取消</div>
            <div class="confirm" @click="confirmStd">确定</div>
        </div>
    </div>
</van-popup>
    </div>
    <img style="width: 100%; height: 100%" v-else src="@/assets/img/tips.webp" alt="">
    </div>
</template>

<script setup>
import Cell from '@/components/Cell.vue'
import { Toast } from 'vant'
import { ref } from '@vue/reactivity'
import { getPaymentManage, getGradeBySchool, getListPaymentStudentDetail, createStudentRecord, createOrderWapPay, createPrepayH5 } from '@/apis/payment'
import { getClassesForSchool } from '@/apis/sch_mgmt'
import { onMounted } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
const route = useRoute()
const store = useStore()
const params = ref({
  gradeId: '',
  classId: '',
  name: ''
})
const stdId = ref('')
const repeatNum = ref(0)
const stdList = ref([])
const show = ref(false)
const stdInfo = ref({})
const showTip = ref(false)
const gradeColumns = ref([{
  value: '10024',
  text: '高一'
},
{
  value: '10025',
  text: '高二'
},
{
  value: '10026',
  text: '高三'
}])
const classColumns = ref([])
const showClassPicker = ref(false)
const showGradePicker = ref(false)
const payMethod = ref('1')
const currentInfo = ref({})
const href = ref('')
onMounted(async() => {
  const ua = window.navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) || ua.match(/AlipayClient/i)) {
    showTip.value = true
    return
  }
  store.dispatch('setclientSessionId')
  await store.dispatch('getSafeCode')

  const res = await getPaymentManage(route.query.id)
  if (res.code === 200) {
    currentInfo.value = res.data
    href.value = window.location.href

    const gradeRes = await getGradeBySchool(res.data.schoolId)
    if (gradeRes.code === 200) {
      gradeColumns.value = gradeRes.data.map((item) => {
        return {
          ...item,
          value: item.gradeId,
          text: item.gradeName
        }
      })
    }
  }
})
const confirmGrade = async(grade) => {
  params.value.classId = ''
  params.value = {
    ...params.value,
    gradeId: grade.text
  }
  showGradePicker.value = false
  classColumns.value = []
  const res = await getClassesForSchool({
    schoolId: currentInfo.value.schoolId,
    gradeId: grade.value
  })
  if (res.code === 200) {
    classColumns.value = res.data.map(item => {
      return {
        text: item.className,
        value: item.classId
      }
    })
  }
}
const cancel = () => {
  show.value = false
}
const confirmStd = () => {
  if (!stdId.value) {
    Toast.fail('请选择')
    return
  }
  stdInfo.value = stdList.value.filter(item => item.studentId === stdId.value)[0]
  createRecord()
  show.value = false
}
const confirmClass = (value) => {
  params.value = {
    ...params.value,
    classId: value.text
  }
  showClassPicker.value = false
}
const orderInfo = ref({})
const createRecord = async() => {
  const res = await createStudentRecord({
    paymentManageSerialNumber: route.query.id,
    schoolId: currentInfo.value.schoolId,
    schoolName: currentInfo.value.schoolName,
    ...stdInfo.value,
    paymentStyle: payMethod.value
  })
  orderInfo.value = res.data
  if (res.code === 200) {
    // let timer = null
    if (payMethod.value === '2') {
      const res1 = await createOrderWapPay({
        ...orderInfo.value
      })
      if (res1.code === 200) {
        const div = document.createElement('divform')
        div.innerHTML = res1.data // data就是接口返回的form 表单字符串
        document.body.appendChild(div)
        const len = document.forms.length - 1

        document.forms[len].setAttribute('target', '_top') // 当前窗口跳转
        document.forms[len].submit()
        // timer = setInterval(async() => {
        //   const res = await checkPay(orderInfo.value.orderOrigin, orderInfo.value.orderId)
        //   if (res.data.state !== 1) {
        //     clearInterval(timer)
        //     completeStudentRecord({
        //       orderId: orderInfo.value.orderId,
        //       orderOrigin: orderInfo.value.orderOrigin
        //     })
        //   }
        // }, 1000)
      }
    } else if (payMethod.value === '1') {
      const res2 = await createPrepayH5({
        ...orderInfo.value
      })
      if (res2.code === 200) {
        const redirectUrl = encodeURIComponent(location.href) // 微信支付回调页面

        const a = document.createElement('a')
        a.href = res2.data + '&redirect_url=' + redirectUrl
        document.body.appendChild(a)

        a.setAttribute('target', '_top') // 当前窗口跳转
        a.click()
      }
      // window.open(res2.data)
      // timer = setInterval(async() => {
      //   const res = await checkWeChatPay(orderInfo.value.orderOrigin, orderInfo.value.orderId)
      //   if (res.data.state !== 1) {
      //     clearInterval(timer)
      //     completeStudentRecord({
      //       orderId: orderInfo.value.orderId,
      //       orderOrigin: orderInfo.value.orderOrigin
      //     })
      //   }
      // }, 1000)
    }
  }
}
const pay = async() => {
  if (!params.value.gradeId) {
    Toast.fail('请选择年级')
    return
  }
  if (!params.value.classId) {
    Toast.fail('请选择班级')
    return
  }
  if (!params.value.name) {
    Toast.fail('请填写姓名')
  }
  const res = await getListPaymentStudentDetail({
    paymentManageSerialNumber: route.query.id,
    schoolId: currentInfo.value.schoolId,
    studentName: params.value.name,
    classId: classColumns.value.filter(item => item.text === params.value.classId)[0].value,
    gradeId: gradeColumns.value.filter(item => item.text === params.value.gradeId)[0].value
  })
  if (res.code === 200) {
    stdList.value = res.data
    if (stdList.value.length > 0) {
      stdId.value = ''
      show.value = true
      repeatNum.value = res.data.length
      stdId.value = res.data[0].studentId
    } else if (res.code === 200) {
      stdInfo.value = stdList.value[0]
      createRecord()
    }
  }
}
</script>

<style lang="less" scoped>
.header{
    height: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    line-height: 1rem;
    justify-content: space-between;
    img{
        width: 0.6rem;
        height: 0.6rem;
    }
    .text{
        font-weight: 650;
        color: #333333;
        font-size: 0.32rem;
    }
}
:deep(.van-cell__value){
        width: 4.76rem;
        flex: none;
        text-align: left;
    }
.payMethod{
    display: flex;
    justify-content: space-between;
    :deep(.van-cell){
      width: 90%;
    }
}
.btn{
    width: 100%;
    height: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .van-button{
        width: 80%;
        height: 1rem;
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        color: #FFFFFF;
        background-color: rgba(55, 117, 246, 1);
        border-radius: 5px;
    }
}
.repeat{
    width: 300px;
    height: 420px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
    position: relative;
    overflow: hidden;
    .title{
        height: 44px;
        line-height: 44px;
        text-align: center;
        font-weight: 650;
        font-style: normal;
        font-size: 16px;
        color: #333333;
        border-bottom: 1px solid rgba(242, 242, 242, 1);
    }
    .desc{
        padding: 13px 20px 0px 20px;
        box-sizing: border-box;
        font-size: 13px;
        p{
            margin-bottom: 0px;
        }
    }
    .stdInfo{
        width: 260px;
        height: 50px;
        font-size: 14px;
        display: flex;
        align-items: center;
        padding-left: 20px;
        border-bottom: 1px solid rgba(242, 242, 242, 1);
    }
    .btnPay{
        position: absolute;
        width: 300px;
        bottom: 0px;
        display: flex;
        height: 50px;
        justify-content: center;
        align-items: center;
        border-top: 1px solid rgba(242, 242, 242, 1);
        div{
            height: 50px;
            flex: 1;
            text-align: center;
            line-height: 50px;
            font-size: 16px;
            color: #409EFF;
            cursor: pointer;
        }
        .cancel{
            border-right: 1px solid rgba(242, 242, 242, 1);
        }
    }
}
.repeatStd{
  height: 150px;
  overflow: scroll;
}
</style>
