import moment from 'moment'
export function fmtNumber(num) {
  let price = num + ''
  if (price.indexOf('.') !== -1) {
    const len = price.split('.')[1].length
    if (len === 1) price = price + '0'
  } else price = price + '.00'
  return price
}

export function formatTimeYYMMDDHHmm(str) {
  return moment(str).format('YYYY-MM-DD HH:mm:ss').toString()
}
