<template>
    <div class="login">
        <div class="login-content">
            <div class="left">
                <div class="logo">
                    <img src="@/assets/img/logoTitle.png" alt="">
                </div>
                <div class="desc">
                    <p style="font-size: 20px; font-weight: 650; color: #666666">
                        代理商数据查询系统
                    </p>
                    <p style="font-size: 14px; font-weight: 400; color: #999999">
                        Information System Platform
                    </p>
                </div>
                <img src="@/assets/svg/loginLeft.svg" alt="">
            </div>
            <div class="right">
                <div class="header">
                    <span style="font-size: 20px; font-weight: 700; color: #666666; margin-right: 10px">用户登录</span>
                    <span style="font-size: 14px; font-weight: 400; color: #999999">USER LOGIN</span>
                </div>
                <a-form
                    :model="loginForm"
                    name="basic"
                    ref="formRef"
                    :label-col="{ span: 8 }"
                    :wrapper-col="{ span: 16 }"
                    autocomplete="off"
                    @finish="onFinish"
                >
                    <a-form-item
                        name="mobile"
                        :rules="[{ required: true, message: '请输入用户名!' }]"
                    >
                        <a-input placeholder="请输入用户名" v-model:value.trim="loginForm.mobile"/>
                    </a-form-item>

                    <a-form-item
                        name="password"
                        :rules="[{ required: true, message: '请输入登录密码!' }]"
                    >
                        <a-input-password placeholder="请输入登录密码" v-model:value="loginForm.password" />
                    </a-form-item>

                    <a-form-item
                        name="code"
                        :rules="[{ required: true, message: '请输入验证码!' }]"
                    >
                        <div class="code">
                            <a-input v-model:value="loginForm.code" />
                            <img @click="changeCode" :src="img" alt="" style="width: 96px">
                        </div>
                    </a-form-item>

                    <a-form-item name="remember">
                        <div class="remember">
                            <a-checkbox v-model:checked="loginForm.remember">记住密码</a-checkbox>
                            <a-tooltip placement="topLeft">
                                <template #title>
                                   <div style="font-size: 12px">
                                    <p>请联系客服重置登录密码</p>
                                    <p>电话：400-1869-786</p>
                                   </div>
                                </template>
                                <span>忘记密码?</span>
                            </a-tooltip>
                        </div>

                    </a-form-item>

                    <a-form-item>
                        <a-button type="primary" html-type="submit">登录</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref } from '@vue/reactivity'
import { getAuthCode } from '@/apis/login'
import { onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { encryptByAES, decryptByAES } from '@/utils/security'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const store = useStore()
const key = '6f0c5ba452b24fa28989e9524d77407a'
const loginForm = reactive({
  mobile: localStorage.getItem('mobile') || '',
  password: localStorage.getItem('password') ? decryptByAES(localStorage.getItem('password'), key) : '',
  remember: localStorage.getItem('remember') || false
})

const img = ref('')

const onFinish = async() => {
  const values = await formRef.value.validateFields()
  values.password = encryptByAES(values.password, key)
  await store.dispatch('login', values)
  if (store.state.user.isLogin) {
    if (values.remember) {
      localStorage.setItem('remember', values.remember)
      localStorage.setItem('mobile', values.mobile)
      localStorage.setItem('password', values.password)
    } else {
      localStorage.removeItem('remember')
      localStorage.removeItem('mobile')
      localStorage.removeItem('password')
    }
    message.success('登录成功')
    router.push('/')
  } else {
    localStorage.removeItem('remember')
    localStorage.removeItem('mobile')
    localStorage.removeItem('password')
  }
}
const changeCode = async() => {
  const res = await getAuthCode()
  img.value = URL.createObjectURL(res)
}
const formRef = ref()
onMounted(async () => {
  store.dispatch('setclientSessionId')
  await store.dispatch('getSafeCode')
  const res = await getAuthCode()
  img.value = URL.createObjectURL(res)
})

</script>

<style lang="less" scoped>
.login{
    width: 100%;
    height: 100%;
    background-image: url('../../assets/svg/loginBgc.svg');
    background-color: rgba(236, 239, 244, 1);
    position: relative;
    overflow: hidden;
    .login-content{
        width: 800px;
        height: 500px;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 5px;
        margin: 100px auto;
        display: flex;
        .left{
            width: 330px;
            position: relative;
            img{
                width: 330px;
                height: 500px;
            }
            .logo{
                width: 160px;
                height: 44px;
                position: absolute;
                left: 81px;
                top: 75px;
                img{
                  width: 160px;
                  height: 44px;
                }
            }
            .desc{
                position: absolute;
                left: 0px;
                top: 130px;
                width: 330px;
                p{
                    text-align: center;
                }
            }
        }
        .right{
            flex: 1;
            padding: 20px 75px;
            .header{
                margin-bottom: 100px;
            }
        }
    }
}
p{
    margin: 0px;
}
.ant-input, .ant-input-password{
    width: 320px;
    height: 50px;
}
.remember{
    width: 320px;
     display: flex;
    justify-content: space-between;
}
.code{
    display: flex;
    justify-content: space-between;
    width: 320px;
    .ant-input{
        width: 200px;
    }
    img{
        cursor: pointer;
    }
}
.ant-btn-primary{
    width: 320px;
    height: 50px;
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    color: #FFFFFF;
    border-radius: 5px;
}
</style>
