import { get, post, patch } from '@/utils/http'
import { SERVER_AGENT } from './api'
const BASE_URL = process.env.VUE_APP_API_BASE_URL

export const getSummaryDetail = (params) => {
  return get(`${SERVER_AGENT}/payment/summary/detail`, params)
}

export const getListAgentStudentRecordPage = (params) => {
  return get(`${SERVER_AGENT}/payment/summary/list/agent/student/record/page`, params)
}

export const getPaymentManage = (paymentManageSerialNumber) => {
  return get(`${SERVER_AGENT}/payment/manage/${paymentManageSerialNumber}`)
}

export const getGradeBySchool = (schoolId) => {
  return get(`${SERVER_AGENT}/payment/student/record/structure/${schoolId}`)
}

// 获取缴费学生信息
export const getListPaymentStudentDetail = (params) => {
  return get(`${SERVER_AGENT}/payment/student/record/list/detail`, params)
}

// 创建学生订单
export const createStudentRecord = (params) => {
  console.log(BASE_URL)
  return post(`${SERVER_AGENT}/payment/student/record`, params)
}

// 支付完成后更新用户缴费信息
export const completeStudentRecord = (params) => {
  return patch(`${SERVER_AGENT}/payment/student/record`, params)
}

// 代理商导出缴费记录
export const exportGroupPaymentManageStudent = (params) => {
  return post(`${SERVER_AGENT}/payment/summary/export/agent/student/record`, params, {}, 'blob')
}

// 支付宝
export const createOrderWapPay = (params) => {
  // const urlMap = {
  //   'http://test.agent.xinjiaoyu.com': 'https://test.xinjiaoyu.com',
  //   'http://agent.xinjiaoyu.com': 'https://test.xinjiaoyu.com'
  // }
  return post('/api/v3/server_third/alipay/wap/pay', params)
}

// 支付宝轮询
export const checkPay = (orderOrigin, orderId) => {
  return get(`https://manage.xinjiaoyu.com/api/v3/server_third/alipay/pay/check/${orderOrigin}/${orderId}`)
}

// 微信轮询
export const checkWeChatPay = (orderOrigin, orderId) => {
  return get(`https://manage.xinjiaoyu.com/api/v3/server_third/wechat/pay/check/${orderOrigin}/${orderId}`)
}

// 微信
export const createPrepayH5 = (params) => {
  // const urlMap = {
  //   'http://test.agent.xinjiaoyu.com': 'https://test.xinjiaoyu.com',
  //   'http://agent.xinjiaoyu.com': 'https://test.xinjiaoyu.com'
  // }
  return post('/api/v3/server_third/wechat/prepay/h5', params)
}
export const getBillPage = (params) => {
  return get(`${SERVER_AGENT}/payment/manage/list/page`, params)
}
// 获取全部应缴名单
export const getSchouldAll = (params) => {
  return get(`${SERVER_AGENT}/payment/manage/student/all/${params}`)
}
// 获取全部应缴名单
export const allExportDetail = (params) => {
  return post(`${SERVER_AGENT}/payment/manage/student/export/all`, params, {}, 'blob')
}

export const getTwoTypeBill = (params) => {
  return get(`${SERVER_AGENT}/payment/manage/student/all/group/${params}`)
}

export const twoExportDetail = (params) => {
  return post(`${SERVER_AGENT}/payment/manage/student/export/group`, params, {}, 'blob')
}
