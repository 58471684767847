<template>
    <div class="statistic">
        <Card title="缴费概要">
            <template #extra>
                <div class="tabs">
                    <div @click="changeTab(item)" :class="{'tab': true, 'active': item.key === key}" v-for="item in tabs" :key="item.key">
                    {{item.name}}
                </div>
                <div :class="{custom: true, active: timeValues.length > 0}" @click="customClick">
                  <div :class="{text: true, bgc: key === '', active: timeValues.length > 0}">{{timeValues.length === 0 ? '自定义' : time}}</div>
                  <a-range-picker ref="custom" :class="{active: timeValues.length > 0}" v-model:value="timeValues" @change="changeTime" />
                </div>
                </div>
            </template>
            <div>
                <a-select
                    ref="select"
                    v-model:value="agentSchool"
                    style="width: 172px"
                    @change="handleChangeAgent"
                >
                    <a-select-option v-for="item in agentSchoolOption" :key="item.schoolId">{{item.schoolName}}</a-select-option>
                </a-select>
                <div class="payDesc">
                     <div class="payUnit">
                        <div class="text">
                            <p style="font-size:14px;">
                                <span style="font-weight:400;color:#999999;">总缴费金额</span>
                            </p>
                            <p style="font-size:28px;">
                                <span style="font-weight:700;color:#666666;">{{currentSchool.paymentSummaryAmount}}</span>
                                <span style="font-size:18px;color:#666666;">元</span>
                            </p>
                            <!-- <p style="font-size:14px;">
                                <span style="font-weight:400;color:#999999;">+45% </span>
                            </p> -->
                            <a-progress :percent="currentSchool.paymentSummaryAmount == 0 ? 0 : parseInt((( +currentSchool.aliPaySummaryAmount + +currentSchool.wechatSummaryAmount) / +currentSchool.paymentSummaryAmount) * 100)" :success="{ percent: currentSchool.paymentSummaryAmount == 0 ? 0 : parseInt((+currentSchool.wechatSummaryAmount / +currentSchool.paymentSummaryAmount) * 100) }" />
                        </div>
                    </div>
                     <div class="payUnit">
                        <div class="text">
                            <p style="font-size:14px;">
                                <span style="font-weight:400;color:#999999;">微信</span>
                            </p>
                            <p style="font-size:28px;">
                                <span style="font-weight:700;color:#666666;">{{currentSchool.wechatSummaryAmount}}</span>
                                <span style="font-size:18px;color:#666666;">元</span>
                            </p>
                            <!-- <p style="font-size:14px;">
                                <span style="font-weight:400;color:#999999;">+45% </span>
                            </p> -->
                            <a-progress :success="{percent: currentSchool.paymentSummaryAmount == 0 ? 0 : parseInt((+currentSchool.wechatSummaryAmount / +currentSchool.paymentSummaryAmount) * 100)}" />
                        </div>
                    </div>
                     <div class="payUnit">
                        <div class="text">
                            <p style="font-size:14px;">
                                <span style="font-weight:400;color:#999999;">支付宝</span>
                            </p>
                            <p style="font-size:28px;">
                                <span style="font-weight:700;color:#666666;">{{currentSchool.aliPaySummaryAmount}}</span>
                                <span style="font-size:18px;color:#666666;">元</span>
                            </p>
                            <!-- <p style="font-size:14px;">
                                <span style="font-weight:400;color:#999999;">+45% </span>
                            </p> -->
                            <a-progress strokeColor="#1890ff" :percent="currentSchool.paymentSummaryAmount == 0 ? 0 : parseInt((+currentSchool.aliPaySummaryAmount / +currentSchool.paymentSummaryAmount) * 100)" />
                        </div>
                    </div>
                     <div class="payUnit">
                        <div class="text">
                            <p style="font-size:14px;">
                                <span style="font-weight:400;color:#999999;">总缴费笔数</span>
                            </p>
                            <p style="font-size:28px;">
                                <span style="font-weight:700;color:#666666;">{{currentSchool.paymentSummaryCount}}</span>
                                <span style="font-size:18px;color:#666666;">笔</span>
                            </p>
                            <!-- <p style="font-size:14px;">
                                <span style="font-weight:400;color:#999999;">+45% </span>
                            </p> -->
                            <a-progress :percent="currentSchool.paymentSummaryCount == 0 ? 0 : parseInt((( +currentSchool.wechatSummaryCount + +currentSchool.aliPaySummaryCount) / +currentSchool.paymentSummaryCount) * 100)" :success="{ percent: currentSchool.paymentSummaryCount == 0 ? 0 : parseInt((+currentSchool.wechatSummaryCount / +currentSchool.paymentSummaryCount) * 100) }" />
                        </div>
                    </div>
                    <div class="payUnit">
                        <div class="text">
                            <p style="font-size:14px;">
                                <span style="font-weight:400;color:#999999;">微信</span>
                            </p>
                            <p style="font-size:28px;">
                                <span style="font-weight:700;color:#666666;">{{currentSchool.wechatSummaryCount}}</span>
                                <span style="font-size:18px;color:#666666;">笔</span>
                            </p>
                            <!-- <p style="font-size:14px;">
                                <span style="font-weight:400;color:#999999;">+45% </span>
                            </p> -->
                            <a-progress :success="{percent: currentSchool.paymentSummaryCount == 0 ? 0 : parseInt((+currentSchool.wechatSummaryCount / +currentSchool.paymentSummaryCount) * 100)}" />
                        </div>
                    </div>
                    <div class="payUnit">
                        <div class="text">
                            <p style="font-size:14px;">
                                <span style="font-weight:400;color:#999999;">支付宝</span>
                            </p>
                            <p style="font-size:28px;">
                                <span style="font-weight:700;color:#666666;">{{currentSchool.aliPaySummaryCount}}</span>
                                <span style="font-size:18px;color:#666666;">笔</span>
                            </p>
                            <!-- <p style="font-size:14px;">
                                <span style="font-weight:400;color:#999999;">+45% </span>
                            </p> -->
                            <a-progress strokeColor="#1890ff" :percent="currentSchool.paymentSummaryCount == 0 ? 0 : parseInt((+currentSchool.aliPaySummaryCount / +currentSchool.paymentSummaryCount) * 100)" />
                        </div>
                    </div>
                </div>
            </div>
        </Card>
        <Card title="缴费单管理" class="table-card">
          <template #extra>
            <div class="card-extra">
              <span>共{{ dataTotal.totalStudentRecordNumber }}条缴费记录</span>
            </div>
          </template>
            <div>
                <div class="search">
                    <a-form-item label="缴费单编号：">
                        <a-input placeholder="请输入缴费单编号" v-model:value="paymentParams.paymentManageSerialNumber" />
                    </a-form-item>
                    <a-form-item label="缴费单名称：">
                        <a-input placeholder="请输入缴费单名称" v-model:value="paymentParams.paymentName" />
                    </a-form-item>
                    <a-form-item label="学校名称：">
                      <a-select
                          ref="select"
                          v-model:value="paymentParams.schoolId"
                          placeholder="请选择学校名称"
                          style="width: 172px"
                      >
                          <a-select-option v-for="item in paymentSchoolOption" :key="item.schoolId">{{item.schoolName}}</a-select-option>
                      </a-select>
                      <a-button @click="searchParams" type="primary" style="margin-right: 20px">查询</a-button>
                    </a-form-item>
                </div>
                <a-table :pagination="paginationPay" @change="handlepaymentChange" :dataSource="paymentList" :columns="paymentCol">
                    <template #bodyCell="{ column, record }">
                        <template v-if="column.key === 'paymentRecordTime'">
                            <span>
                                {{formatTimeYYMMDDHHmm(record.paymentRecordTime)}}
                            </span>
                        </template>
                        <template v-if="column.key === 'paymentStatus'">
                            <span>
                                {{obj[record.paymentStatus] || ''}}
                            </span>
                        </template>
                        <template
                          v-if="column.key === 'totalStudentNumber'"
                            >
                          <a
                            style="color: #409EFF"
                            @click="showAllStu(record)"
                          >{{
                            record.totalStudentNumber
                          }}</a>
                        </template>
                        <template
                          v-if="column.key === 'twoNumber'"
                        >
                          <a
                            style="color: #409EFF"
                            @click="showTwoStu(record)"
                          >{{
                            record.twoNumber
                          }}</a>
                        </template>
                        <template
                          v-if="column.key === 'action'"
                        >
                          <a
                            style="color: #409EFF"
                            @click="configMenus(record)"
                          >缴费二维码</a>
                        </template>
                    </template>
                </a-table>
            </div>
        </Card>
        <Card title="缴费详情" class="table-card">
          <template #extra>
            <div class="card-extra">
              <span>共{{ dataTotal.totalStudentRecordNumber }}条缴费记录，缴费总计{{ dataTotal.totalPaymentAmount }}元，其中已退款{{ dataTotal.totalRefundAmount }}元</span>
              <a-button type="primary" @click="exportGroupPaymentManage">导出表格</a-button>
            </div>
          </template>
            <div>
                <div class="search">
                    <a-form-item label="缴费时间：">
                        <a-range-picker :allowClear="false" v-model:value="params.time" />
                    </a-form-item>
                    <a-form-item label="缴费单名称：">
                        <a-input placeholder="请输入缴费单名称" v-model:value="params.paymentName" />
                    </a-form-item>
                    <a-form-item label="学校名称：">
                        <a-input placeholder="请输入学校名称" v-model:value="params.schoolName" />
                    </a-form-item>
                    <a-form-item label="年级：">
                        <a-input placeholder="请输入年级" v-model:value="params.gradeName" />
                    </a-form-item>
                    <a-form-item label="班级：">
                        <a-input placeholder="请输入班级" v-model:value="params.className" />
                    </a-form-item>
                </div>
                <div class="search">
                    <a-form-item label="姓名：">
                        <a-input placeholder="请输入学生姓名" v-model:value="params.studentName" />
                    </a-form-item>
                    <a-form-item label="支付方式：">
                        <a-select
                            ref="select"
                            style="width: 160px"
                            placeholder="请选择"
                            v-model:value="params.paymentStyle"
                        >
                            <a-select-option :value="1">微信支付</a-select-option>
                            <a-select-option :value="2">支付宝支付</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-button @click="searchData" type="primary" style="margin-right: 20px">查询</a-button>
                    <a-button @click="reset">重置</a-button>
                </div>
                <a-table :pagination="pagination" @change="handleChange" :dataSource="dataSource" :columns="payColumns">
                    <template #bodyCell="{ column, record }">
                        <template v-if="column.key === 'paymentRecordTime'">
                            <span>
                                {{formatTimeYYMMDDHHmm(record.paymentRecordTime)}}
                            </span>
                        </template>
                        <template v-if="column.key === 'paymentStatus'">
                            <span>
                                {{obj[record.paymentStatus] || ''}}
                            </span>
                        </template>
                    </template>
                </a-table>
            </div>
        </Card>
        <a-modal
          v-model:visible="allShouldBill"
          title="缴费学生名单"
          @ok="allShouldBill= false"
        >
          <p class="don">
            <span>共{{ allNum }}位学生需要缴费</span>
            <a @click="allExport(allExportNum)">导出</a>
          </p>

          <a-list
            size="small"
            item-layout="horizontal"
            :data-source="titledata"
          >
            <template #renderItem="{ item }">
              <a-list-item>
                {{ item.className }}：
                <span
                  v-for="(stu, index) in item.paymentStudentList"
                  :key="index"
                >
                  {{ stu.studentName
                  }}<span v-if="stu.flag">({{ stu.studentCode }})</span><span v-if="index != item.paymentStudentList.length - 1">，</span>
                </span>
              </a-list-item>
            </template>
          </a-list>
        </a-modal>
        <a-modal
          v-model:visible="billingOrNot"
          title="已缴费/未缴费学生名单"
          @ok="allShouldBill.value = false"
        >
        <div>
        <a-radio-group
          v-model:value="twoBillType"
          button-style="solid"
        >
          <a-radio-button value="1">
            已缴费
          </a-radio-button>
          <a-radio-button value="2">
            未缴费
          </a-radio-button>
        </a-radio-group>
        <div v-if="twoBillType == 1">
          <p class="don">
            <span>共{{ finishNum }}位学生已缴费</span><a @click="twoExport(twoExportNum)">导出</a>
          </p>
          <a-list
            size="small"
            item-layout="horizontal"
            :data-source="finishData"
          >
            <template #renderItem="{ item }">
              <a-list-item>
                {{ item.className }}：
                <span
                  v-for="(stu, index) in item.paymentStudentList"
                  :key="index"
                >
                  <span v-if="stu.paymentStatus > 1">已退款：</span>{{ stu.studentName
                  }}<span v-if="stu.flag">({{ stu.studentCode }})</span><span
                    v-if="index != item.paymentStudentList.length - 1"
                  >，</span>
                </span>
              </a-list-item>
            </template>
          </a-list>
        </div>
        <div v-else>
          <p class="don">
            <span>共{{ unfinishNum }}位学生未缴费</span><a @click="twoExport(twoExportNum)">导出</a>
          </p>
          <a-list
            size="small"
            item-layout="horizontal"
            :data-source="unfinishData"
          >
            <template #renderItem="{ item }">
              <a-list-item>
                {{ item.className }}：
                <span
                  v-for="(stu, index) in item.paymentStudentList"
                  :key="index"
                >
                  {{ stu.studentName
                  }}<span v-if="stu.flag">({{ stu.studentCode }})</span><span
                    v-if="index != item.paymentStudentList.length - 1"
                  >，</span>
                </span>
              </a-list-item>
            </template>
          </a-list>
        </div>
      </div>
        </a-modal>
        <a-modal
          v-model:visible="configMenusModal"
          title="二维码预览"
          @ok="configMenusModal= false"
        >
          <div
            style="display: flex;
          flex-direction: column;
          align-items: center;"
          >
            <p>缴费单名称：{{ payName }}，金额：{{ paymentAmount }}元</p>
            <img
              :src="paymentQrCodeUrl"
              alt="picture"
              style="width:30%"
            >
            <a @click="downloadCode(paymentQrCodeUrl, payName)">下载二维码</a>
          </div>
        </a-modal>
    </div>
</template>

<script setup>
import Card from '@/components/Card.vue'
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { message } from 'ant-design-vue'
import { getSummaryDetail, getListAgentStudentRecordPage, exportGroupPaymentManageStudent, getBillPage, getSchouldAll, allExportDetail, getTwoTypeBill, twoExportDetail } from '@/apis/payment'
import dayjs from 'dayjs'
import { formatTimeYYMMDDHHmm } from '@/utils/util.js'

import moment from 'moment'
moment.locale('zh-cn')
const agentSchoolOption = ref([])
const agentSchool = ref('')
const handleChangeAgent = () => {
  currentSchool.value = agentSchoolOption.value.filter(item => item.schoolId === agentSchool.value)[0] || agentSchoolOption.value[0]
  agentSchool.value = currentSchool.value.schoolId
}
const obj = {
  2: '已退款'
}
const downUrl = ref('')
const dateFormat = 'YYYY-MM-DD'
const year = new Date().getFullYear()
const date = new Date().getDate()
const month = new Date().getMonth()
const params = ref({
  time: [dayjs(moment(`${year - 1}-${month + 1}-${date}`).format(dateFormat).toString(), dateFormat), dayjs(moment(`${year}-${month + 1}-${date}`).format(dateFormat).toString(), dateFormat)],
  paymentStyle: '',
  gradeName: '',
  className: '',
  studentName: '',
  paymentName: '',
  schoolName: ''
})
const searchData = () => {
  pagination.value = {
    ...pagination.value,
    current: 1,
    pageSize: 10,
    total: 0
  }
  getTableData()
}
const reset = () => {
  pagination.value = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50'],
    showTotal: total => `共有 ${total} 条数据`
  }
  params.value = {
    time: [dayjs(moment(`${year - 1}-${month + 1}-${date}`).format(dateFormat).toString(), dateFormat), dayjs(moment(`${year}-${month + 1}-${date}`).format(dateFormat).toString(), dateFormat)],
    paymentStyle: '',
    classId: '',
    gradeId: '',
    studentName: '',
    paymentName: '',
    schoolName: ''
  }
  getTableData()
}
const currentSchool = ref({})
const pagination = ref({
  current: 1,
  pageSize: 10,
  total: 0,
  showSizeChanger: true,
  pageSizeOptions: ['10', '20', '50'],
  showTotal: total => `共有 ${total} 条数据`
})
const key = ref('1')
const getTime = (key) => {
  if (key === '1') {
    // const year = new Date().getFullYear()
    // const day = new Date().getDay()
    // const date = new Date().getDate()
    // const month = new Date().getMonth()
    // return [`${year}-${month + 1}-${date - day + 1}`, `${year}-${month + 1}-${date + 7 - day}`]
    return [
      moment().startOf('week').format('YYYY-MM-DD'),
      moment().endOf('week').format('YYYY-MM-DD')
    ]
  } else if (key === '2') {
    // const year = new Date().getFullYear()
    // const month = new Date().getMonth()
    // return [`${year}-${month + 1}-1`, `${year}-${month + 1}-${new Date(year, month + 1, 0).getDate()}`]
    return [
      moment().startOf('month').format('YYYY-MM-DD'),
      moment().endOf('month').format('YYYY-MM-DD')
    ]
  } else if (key === '3') {
    // const year = new Date().getFullYear()
    // return [`${year}-1-1`, `${year}-12-31`]
    return [
      moment().startOf('year').format('YYYY-MM-DD'),
      moment().endOf('year').format('YYYY-MM-DD')
    ]
  }
}
const exportGroupPaymentManage = async() => {
  const res = await exportGroupPaymentManageStudent(
    {
      paymentAgentSerialNumber: localStorage.getItem('paymentAgentSerialNumber'),
      paymentRecordBeginTimeString: params.value.time[0].format(dateFormat).toString(),
      paymentRecordEndTimeString: params.value.time[1].format(dateFormat).toString(),
      className: params.value.className,
      gradeName: params.value.gradeName,
      studentName: params.value.studentName,
      paymentName: params.value.paymentName,
      paymentStyle: params.value.paymentStyle,
      schoolName: params.value.schoolName
    }
  )
  const FileName = '学生缴费单.xlsx'
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(res)
  link.download = FileName
  document.body.append(link)
  await link.click()
  await document.body.removeChild(link)
  await window.URL.revokeObjectURL(link.href)
}
const tabs = [{
  name: '本周',
  value: getTime('1'),
  key: '1'
},
{
  name: '本月',
  value: getTime('2'),
  key: '2'
},
{
  name: '今年',
  value: getTime('3'),
  key: '3'
}
]
const payColumns = [
  {
    title: '缴费时间',
    key: 'paymentRecordTime',
    dataIndex: 'paymentRecordTime'
  },
  {
    title: '缴费单名称',
    key: 'paymentName',
    dataIndex: 'paymentName'
  },
  {
    title: '缴费金额',
    key: 'paymentAmount',
    dataIndex: 'paymentAmount'
  },
  {
    title: '支付方式',
    key: 'paymentStyleName',
    dataIndex: 'paymentStyleName'
  },
  {
    title: '学校',
    key: 'schoolName',
    dataIndex: 'schoolName'
  },
  {
    title: '年级',
    key: 'gradeName',
    dataIndex: 'gradeName'
  },
  {
    title: '班级',
    key: 'className',
    dataIndex: 'className'
  },
  {
    title: '姓名',
    key: 'studentName',
    dataIndex: 'studentName'
  },
  {
    title: '新教育号',
    key: 'studentCode',
    dataIndex: 'studentCode'
  },
  {
    title: '备注',
    key: 'paymentStatus',
    dataIndex: 'paymentStatus'
  }
]
const paymentCol = [
  {
    title: '缴费单编号',
    key: 'paymentManageSerialNumber',
    dataIndex: 'paymentManageSerialNumber'
  },
  {
    title: '缴费时间',
    key: 'paymentRecordTime',
    dataIndex: 'paymentRecordTime'
  },
  {
    title: '缴费单名称',
    key: 'paymentName',
    dataIndex: 'paymentName'
  },
  {
    title: '备注',
    key: 'paymentRemark',
    dataIndex: 'paymentRemark',
    width: '500px',
    ellipsis: true
  },
  {
    title: '缴费学校',
    key: 'schoolName',
    dataIndex: 'schoolName'
  },
  {
    title: '缴费年级',
    key: 'gradeName',
    dataIndex: 'gradeName'
  },
  {
    title: '金额',
    key: 'paymentAmount',
    dataIndex: 'paymentAmount'
  },
  {
    title: '应缴学生数',
    key: 'totalStudentNumber',
    dataIndex: 'totalStudentNumber'
  },
  {
    title: '已缴数/未缴数',
    key: 'twoNumber',
    dataIndex: 'twoNumber'
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action'
  }

  // {
  //   title: '支付方式',
  //   key: 'paymentStyleName',
  //   dataIndex: 'paymentStyleName'
  // },
]
const dataSource = ref([])
const paymentList = ref([])
const dataTotal = ref({
  totalPaymentAmount: 0,
  totalRefundAmount: 0,
  totalStudentRecordNumber: 0
})
const changeTab = (item) => {
  timeValues.value = []
  if (item.key !== key.value) {
    key.value = item.key
    getData()
  }
}
const time = ref('')
const timeValues = ref([])
const changeTime = () => {
  if (timeValues.value == null) {
    message.error('请选择时间')
    return
  }
  const value1 = timeValues.value[0].format(dateFormat).toString().split('-')
  const value2 = timeValues.value[1].format(dateFormat).toString().split('-')
  time.value = `${value1[0] + '年' + value1[1] + '月' + value1[2] + '日'}-${value2[0] + '年' + value2[1] + '月' + value2[2] + '日'}`
  getData()
}
const getTableData = async() => {
  const res = await getListAgentStudentRecordPage({
    paymentAgentSerialNumber: localStorage.getItem('paymentAgentSerialNumber'),
    paymentRecordBeginTimeString: params.value.time[0].format(dateFormat).toString(),
    paymentRecordEndTimeString: params.value.time[1].format(dateFormat).toString(),
    current: pagination.value.current,
    size: pagination.value.pageSize,
    className: params.value.className,
    gradeName: params.value.gradeName,
    studentName: params.value.studentName,
    paymentName: params.value.paymentName,
    paymentStyle: params.value.paymentStyle,
    schoolName: params.value.schoolName
  })
  dataSource.value = res.data.paymentStudentRecordPage.records
  dataTotal.value = {
    totalPaymentAmount: res.data.totalPaymentAmount,
    totalRefundAmount: res.data.totalRefundAmount,
    totalStudentRecordNumber: res.data.totalStudentRecordNumber
  }
  pagination.value = {
    ...pagination.value,
    total: Number(res.data.paymentStudentRecordPage.total)
  }
}
const handleChange = (value) => {
  pagination.value = {
    ...pagination.value,
    current: value.current,
    pageSize: value.pageSize
  }
  getTableData()
}
const paymentParams = ref({
  paymentManageSerialNumber: '',
  paymentName: '',
  schoolId: ''
})
const paymentSchoolOption = ref([])
const paginationPay = ref({
  current: 1,
  pageSize: 10,
  total: 0,
  showSizeChanger: true,
  pageSizeOptions: ['10', '20', '50'],
  showTotal: total => `共有 ${total} 条数据`
})
const handlepaymentChange = (value) => {
  paginationPay.value = {
    ...paginationPay.value,
    current: value.current,
    pageSize: value.pageSize
  }
  getPaymentList()
}
const searchParams = () => {
  paginationPay.value = {
    ...paginationPay.value,
    current: 1,
    pageSize: 10,
    total: 0
  }
  getPaymentList()
}
const getPaymentList = async() => {
  const res = await getBillPage({
    paymentAgentSerialNumber: localStorage.getItem('paymentAgentSerialNumber'),
    current: paginationPay.value.current,
    size: paginationPay.value.pageSize,
    paymentManageSerialNumber: paymentParams.value.paymentManageSerialNumber,
    paymentName: paymentParams.value.paymentName,
    schoolId: paymentParams.value.schoolId
  })
  if (res.code === 200) {
    res.data.records.map((item) => {
      downUrl.value = item.paymentQrCodeUrl
      item.twoNumber =
            item.finishStudentNumber + '/' + item.unFinishStudentNumber
    })
    paymentList.value = res.data.records
    paginationPay.value = {
      ...paginationPay.value,
      total: Number(res.data.total)
    }
  }
}
const getData = async() => {
  const o = tabs.filter(item => item.key === key.value)
  let paymentRecordDayBeginString
  let paymentRecordDayEndString
  if (o[0]) {
    paymentRecordDayBeginString = moment(o[0].value[0]).format(dateFormat).toString()
    paymentRecordDayEndString = moment(o[0].value[1]).format(dateFormat).toString()
  } else {
    paymentRecordDayBeginString = timeValues.value[0].format(dateFormat).toString()
    paymentRecordDayEndString = timeValues.value[1].format(dateFormat).toString()
  }
  const res = await getSummaryDetail({
    paymentRecordDayBeginString,
    paymentRecordDayEndString,
    paymentAgentSerialNumber: localStorage.getItem('paymentAgentSerialNumber')
  })
  agentSchoolOption.value = res.data
  paymentSchoolOption.value = res.data.filter(item => item.schoolId !== '1')
  if (currentSchool.value.schoolId) {
    handleChangeAgent()
  } else {
    agentSchool.value = res.data[0] ? res.data[0].schoolId : ''
    currentSchool.value = res.data[0] ? res.data[0] : {}
  }
}
const titledata = ref([])
const allNum = ref('')
const allExportNum = ref('')
const allShouldBill = ref(false)
const showAllStu = async(e) => {
  allNum.value = 0
  allExportNum.value = e
  const res = await getSchouldAll(e.paymentManageSerialNumber)
  if (res.code === 200) {
    titledata.value = res.data
    titledata.value.map((item) => {
      for (let i = 0; i < item.paymentStudentList.length; i++) {
        for (let j = i + 1; j < item.paymentStudentList.length; j++) {
          if (
            item.paymentStudentList[i].studentName ===
            item.paymentStudentList[j].studentName
          ) {
            item.paymentStudentList[i].flag = true
            item.paymentStudentList[j].flag = true
          }
        }
      }
    })
    if (res.data) {
      titledata.value.map((item) => {
        allNum.value = allNum.value + item.paymentStudentList.length
      })
    }
    allShouldBill.value = true
  }
}
const allExport = async(e) => {
  const res = await allExportDetail({
    paymentManageSerialNumber: e.paymentManageSerialNumber
  })
  const FileName = e.paymentName + '应缴学生名单.xlsx'
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(res)
  link.download = FileName
  document.body.append(link)
  await link.click()
  await document.body.removeChild(link)
  await window.URL.revokeObjectURL(link.href)
}
const finishData = ref([])
const unfinishData = ref([])
const finishNum = ref('')
const unfinishNum = ref('')
const twoExportNum = ref({})
const billingOrNot = ref(false)
const twoBillType = ref('1')
const showTwoStu = async(e) => {
  finishNum.value = 0
  unfinishNum.value = 0
  twoExportNum.value = e
  const res = await getTwoTypeBill(e.paymentManageSerialNumber)
  if (res.code === 200) {
    finishData.value = res.data.finishStudentList
    finishData.value.map((item) => {
      for (let i = 0; i < item.paymentStudentList.length; i++) {
        for (let j = i + 1; j < item.paymentStudentList.length; j++) {
          if (
            item.paymentStudentList[i].studentName ===
                item.paymentStudentList[j].studentName
          ) {
            item.paymentStudentList[i].flag = true
            item.paymentStudentList[j].flag = true
          }
        }
      }
    })
    unfinishData.value = res.data.unFinishStudentList
    unfinishData.value.map((item) => {
      for (let i = 0; i < item.paymentStudentList.length; i++) {
        for (let j = i + 1; j < item.paymentStudentList.length; j++) {
          if (
            item.paymentStudentList[i].studentName ===
                item.paymentStudentList[j].studentName
          ) {
            item.paymentStudentList[i].flag = true
            item.paymentStudentList[j].flag = true
          }
        }
      }
    })

    if (res.data.finishStudentList) {
      finishData.value.map((item) => {
        finishNum.value = finishNum.value + item.paymentStudentList.length
      })
    }
    if (res.data.unFinishStudentList) {
      unfinishData.value.map((item) => {
        unfinishNum.value =
              unfinishNum.value + item.paymentStudentList.length
      })
    }
    billingOrNot.value = true
  }
}
const twoExport = async(e) => {
  const res = await twoExportDetail({
    paymentManageSerialNumber: e.paymentManageSerialNumber
  })
  const FileName = e.paymentName + '已缴/未缴费名单.xlsx'
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(res)
  link.download = FileName
  document.body.append(link)
  await link.click()
  await document.body.removeChild(link)
  await window.URL.revokeObjectURL(link.href)
}
const configMenusModal = ref(false)
const paymentQrCodeUrl = ref('')
const paymentAmount = ref('')
const payName = ref('')
const downUrlImg = ref('')
const configMenus = async(record) => {
  configMenusModal.value = true
  paymentQrCodeUrl.value = record.paymentQrCodeUrl
  paymentAmount.value = record.paymentAmount
  payName.value = record.paymentName
  downUrlImg.value = record.downUrl
  // this.spinning = true;
  // this.selectedKeys = [];
  // await this.getMenuList();
  // await this.getRoleAndMenu(record);
  // this.spinning = false;
}
onMounted(() => {
  getData()
  getTableData()
  getPaymentList()
})
const custom = ref()
const customClick = () => {
  key.value = ''
}
</script>

<style lang="less" scoped>
.table-card {
  :deep(.ant-card-head-title) {
    width: 100px;
    flex: none;
  }
  :deep(.ant-card-extra) {
    flex: 1;
    .card-extra {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.statistic{
    padding: 30px;
}
.payDesc{
    display: flex;
    margin-top: 20px;
    .payUnit{
        width: 292px;
        height: 140px;
        line-height: 32px;
        p{
            margin-bottom: 0px;
        }
        .text{
            align-self: flex-start;
            padding: 10px 0px 0px 30px;
            box-sizing: border-box;
        }
    }
}
:deep(.ant-progress-text){
    display: none;
}
.search{
    display: flex;
    :deep(.ant-input){
        width: 158px;
    }
    :deep(.ant-form-item){
        margin-right: 20px;
    }
}
.tabs{
    display: flex;
}
.tab{
    color: rgb(153, 153, 153);
    font-size: 13px;
    width: 80px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    border-radius: 80px;
    cursor: pointer;
    &.active{
        background-color: rgba(64, 158, 255, 1);
        color: #fff;
    }
    &:hover{
        color: rgba(64, 158, 255, 1);
    }
    &.active:hover{
        color: #fff;
    }
}
.custom {
  height: 35px;
  position: relative;
  width: 80px;
  cursor: pointer;
  &.active{
    width: 240px;
  }
  .text{
    position: absolute;
    left: 0px;
    top: 0px;
    text-align: center;
    width: 80px;
    height: 35px;
    line-height: 35px;
    border-radius: 80px;
    color: #999;
    &.bgc{
      background-color: rgba(64, 158, 255, 1);
      color
      : #fff;
    }
    &.active{
    width: 240px;
  }
  }
  :deep(.ant-picker-range){
    position: absolute;
    left: 0px;
    top: 0px;
    width: 80px;
    height: 35px;
    background: transparent;
    border-color: transparent;
    .ant-picker-input > input{
      display: none;
    }
    .anticon svg{
      display: none;
    }
    &.active{
    width: 240px;
  }
  }
}
.don {
  display: flex;
  justify-content: space-between;
}
</style>
