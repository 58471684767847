<template>
  <a-layout class="layout">
    <a-layout-header>
      <div class="logo">
        <img src="@/assets/img/logo.png" alt="">
      </div>
      <a-menu
        v-model:selectedKeys="selectedKeys"
        theme="dark"
        mode="horizontal"
        :style="{ lineHeight: '64px' }"
      >
        <a-menu-item key="statistic">缴费统计</a-menu-item>
        <!-- <a-menu-item key="2">nav 2</a-menu-item>
        <a-menu-item key="3">nav 3</a-menu-item> -->
      </a-menu>
    </a-layout-header>
    <a-dropdown>
      <a @click.prevent>
        <a-tooltip placement="left">
          <template
            #title
            v-if="localStorage.getItem('paymentAgentName').length > 8"
          >
            {{localStorage.getItem('paymentAgentName')}}
          </template>
          {{localStorage.getItem('paymentAgentName')}}
        </a-tooltip>
      </a>
      <template #overlay>
        <a-menu @click="onClick">
          <a-menu-item key="1">修改密码</a-menu-item>
          <a-menu-item key="2">退出系统</a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
    <a-modal v-model:visible="resetVisible" v-if="resetVisible" title="修改密码" :width="500" @cancel="reset" @ok="handleOk">
      <a-form
        :model="resetForm"
        name="basic"
        ref="formRef"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        autocomplete="off"
        @finish="onFinish"
      >
        <a-form-item
          label="旧密码"
          name="oldPassword"
          :rules="rulesRef.oldPassword"
        >
          <a-input-password placeholder="请输入旧密码" v-model:value="resetForm.oldPassword" />
        </a-form-item>

        <a-form-item
          label="新密码"
          name="password"
          :rules="rulesRef.password"
        >
          <a-input-password placeholder="请输入新密码" v-model:value="resetForm.password" />
        </a-form-item>

        <a-form-item
          label="确认新密码"
          name="checkPassword"
          :rules="rulesRef.checkPassword"
        >
          <a-input-password placeholder="请再次输入新密码" v-model:value="resetForm.checkPassword" />
        </a-form-item>
      </a-form>
    </a-modal>
  </a-layout>
</template>

<script setup>
import { ref } from '@vue/reactivity'
import { useRoute, useRouter } from 'vue-router'
import { Form, message } from 'ant-design-vue'
import { useStore } from 'vuex'
import { updatePassword } from '@/apis/login'
import { encryptByAES } from '@/utils/security'
const route = useRoute()
const router = useRouter()
const useForm = Form.useForm
const store = useStore()
const selectedKeys = ref([route.name])
const resetVisible = ref(false)
const resetForm = ref({
  oldPassword: '',
  password: '',
  checkPassword: ''
})
const validatePassword = (rule, value, callback) => {
  if (value && value.indexOf(' ') !== -1) {
    callback(new Error('不能包括空格'))
  } else if (value && !/^(?![A-Z]+$)(?![a-z]+$)(?!\d+$)(?![\W_]+$)\S+$/.test(value)) {
    callback(new Error('密码必须包含数字、字母、特殊符号中的2项'))
  }
  callback()
}
const rulesRef = ref({
  oldPassword: [
    { required: true, message: '请输入旧密码' }
  ],
  password: [
    { required: true, message: '请输入新密码' },
    { min: 8, message: '长度为8-12个字符' },
    { max: 12, message: '长度为8-12个字符' },
    { validator: validatePassword }
  ],
  checkPassword: [
    { required: true, message: '请再次输入新密码' },
    { min: 8, message: '长度为8-12个字符' },
    { max: 12, message: '长度为8-12个字符' },
    { validator: validatePassword, trigger: 'blur' }
  ]
})
const localStorage = window.localStorage
const onClick = (value) => {
  if (value.key === '1') {
    resetVisible.value = true
  } else if (value.key === '2') {
    store.commit('LOGOUT')
    router.push('/login')
  }
}
const { resetFields } = useForm(resetForm.value, rulesRef)
const formRef = ref()
const key = '6f0c5ba452b24fa28989e9524d77407a'
const handleOk = async() => {
  const values = await formRef.value.validateFields()
  values.oldPassword = encryptByAES(values.oldPassword, key)
  values.password = encryptByAES(values.password, key)
  values.checkPassword = encryptByAES(values.checkPassword, key)
  values.paymentAgentSerialNumber = Number(localStorage.getItem('paymentAgentSerialNumber'))
  const res = await updatePassword(values)
  if (res.code === 200) {
    message.success('重置密码成功')
    localStorage.removeItem('remember')
    localStorage.removeItem('mobile')
    localStorage.removeItem('password')
    store.commit('LOGOUT')
    router.push('/login')
  }
}
const reset = () => {
  resetFields()
  resetVisible.value = false
}
</script>

<style lang="less" scoped>
.ant-layout-header{
  background-color: rgba(51, 55, 68, 1);
  padding: 0;
}
.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    color: rgba(255, 255, 255, 0.65);
    background-color: rgba(51, 55, 68, 1)
}
.logo{
    float: left;
    img{
        width: 35px;
    height: 37px;
    }
    margin-right: 20px;
}
:deep(.ant-menu.ant-menu-dark .ant-menu-item-selected){
    background-color: rgba(64, 158, 255, 1);
}
:deep(.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover){
    background-color: rgba(64, 158, 255, 1);
}
.layout{
  background-color: rgba(51, 55, 68, 1);
  flex-direction: row;
  justify-content: space-between;
  padding: 0 30px;
  :deep(.ant-dropdown-trigger){
    display: inline-block;
    width: 120px;
    text-align: center;
    line-height: 64px;
    color: #999;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  :deep(.ant-dropdown-trigger:hover){
    background-color: rgba(0, 0, 0, .5);
  }
}
</style>
